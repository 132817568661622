"use strict";

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));
var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _vue = _interopRequireDefault(require("vue"));
var _strftime = _interopRequireDefault(require("strftime"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
var bufferTools = _interopRequireWildcard(require("@/libs/bufferTools"));
var _RemoveBeforeUpdate = _interopRequireDefault(require("./utils/RemoveBeforeUpdate"));
var _MessageListMessageCompact = _interopRequireDefault(require("./MessageListMessageCompact"));
var _MessageListMessageModern = _interopRequireDefault(require("./MessageListMessageModern"));
var _MessageListMessageInline = _interopRequireDefault(require("./MessageListMessageInline"));
var _LoadingAnimation = _interopRequireDefault(require("./LoadingAnimation"));
var _BufferKey = _interopRequireDefault(require("./BufferKey"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
require('@/libs/polyfill/Element.closest');
var log = _Logger.default.namespace('MessageList.vue');

// If we're scrolled up more than this many pixels, don't auto scroll down to the bottom
// of the message list
var BOTTOM_SCROLL_MARGIN = 60;
var _default = {
  components: {
    RemoveBeforeUpdate: _RemoveBeforeUpdate.default,
    MessageListMessageModern: _MessageListMessageModern.default,
    MessageListMessageCompact: _MessageListMessageCompact.default,
    MessageListMessageInline: _MessageListMessageInline.default,
    LoadingAnimation: _LoadingAnimation.default,
    BufferKey: _BufferKey.default
  },
  props: ['buffer'],
  data: function data() {
    return {
      smooth_scroll: false,
      auto_scroll: true,
      // Automatically scroll to bottom
      showScrollButton: false,
      // Control visibility of the scroll button
      force_smooth_scroll: null,
      chathistoryAvailable: true,
      hover_nick: '',
      message_info_open: null,
      timeToClose: false,
      startClosing: false,
      selectedMessages: Object.create(null)
    };
  },
  computed: {
    thisMl: function thisMl() {
      return this;
    },
    shouldAutoEmbed: function shouldAutoEmbed() {
      if (this.buffer.isChannel() && this.buffer.setting('inline_link_auto_previews')) {
        return true;
      }
      if (this.buffer.isQuery() && this.buffer.setting('inline_link_auto_previews_query')) {
        return true;
      }
      return false;
    },
    listType: function listType() {
      if (this.$state.setting('messageLayout')) {
        log.info('Deprecation Warning: The config option \'messageLayout\' has been moved to buffers.messageLayout');
      }
      return this.buffer.setting('messageLayout') || this.$state.setting('messageLayout');
    },
    useExtraFormatting: function useExtraFormatting() {
      // Enables simple markdown formatting
      return this.buffer.setting('extra_formatting');
    },
    shouldShowChathistoryTools: function shouldShowChathistoryTools() {
      // Only show it if we're connected
      if (this.buffer.getNetwork().state !== 'connected') {
        return false;
      }
      var isCorrectBufferType = this.buffer.isChannel() || this.buffer.isQuery();
      var isSupported = !!this.buffer.getNetwork().ircClient.chathistory.isSupported();
      return isCorrectBufferType && isSupported && (0, _flags.default)(this.buffer).chathistory_available;
    },
    shouldRequestChannelKey: function shouldRequestChannelKey() {
      return this.buffer.getNetwork().state === 'connected' && this.buffer.isChannel() && (0, _flags.default)(this.buffer).channel_badkey;
    },
    ourNick: function ourNick() {
      return this.buffer ? this.buffer.getNetwork().nick : '';
    },
    filteredMessagesGroupedDay: function filteredMessagesGroupedDay() {
      var days = [];
      var lastDay = null;
      this.filteredMessages.forEach(function (message) {
        if (!lastDay || message.day_num !== lastDay) {
          days.push({
            dayNum: message.day_num,
            messages: []
          });
          lastDay = message.day_num;
        }
        days[days.length - 1].messages.push(message);
      });
      return days;
    },
    filteredMessages: function filteredMessages() {
      return bufferTools.orderedMessages(this.buffer);
    },
    shouldShowJoiningLoader: function shouldShowJoiningLoader() {
      return this.buffer.isChannel() && this.buffer.enabled && !this.buffer.joined && this.buffer.getNetwork().state === 'connected';
    }
  },
  watch: {
    filteredMessages: function filteredMessages() {
      var _this = this;
      this.checkScrollingState();
      this.$nextTick(function () {
        _this.maybeScrollToBottom();
      });
    },
    buffer: function buffer(newBuffer, oldBuffer) {
      var _this2 = this;
      if (oldBuffer) {
        oldBuffer.isMessageTrimming = true;
      }
      if (!newBuffer) {
        return;
      }
      this.message_info_open = null;
      if (this.buffer.getNetwork().state === 'connected') {
        (0, _flags.default)(newBuffer).has_opened = true;
      }
      this.auto_scroll = true;
      this.force_smooth_scroll = false;
      this.$nextTick(function () {
        _this2.scrollToBottom();
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.addCopyListeners();
    this.$nextTick(function () {
      _this3.scrollToBottom();
    });
    this.listen(this.$state, 'mediaviewer.opened', function () {
      _this3.$nextTick(_this3.maybeScrollToBottom.apply(_this3));
    });
    this.listen(this.$state, 'messagelist.scrollto', function (opt) {
      if (opt && opt.id) {
        _this3.maybeScrollToId(opt.id);
      }
    });
    var scroller = this.$refs.scroller;
    // Add scroll event listener
    scroller.addEventListener('scroll', this.debounce(this.handleScroll, 100));
    // Initial check for scroll position
    this.checkScroll();
  },
  beforeDestroy: function beforeDestroy() {
    var scroller = this.$refs.scroller;
    // Remove scroll event listener
    scroller.removeEventListener('scroll', this.debounce(this.handleScroll, 100));
  },
  methods: {
    isTemplateVue: function isTemplateVue(template) {
      var isVue = template instanceof _vue.default;
      if (isVue && !window.kiwi_deprecations_messageTemplate) {
        window.kiwi_deprecations_messageTemplate = true;
        // eslint-disable-next-line no-console
        console.warn('deprecated message.template or message.bodyTemplate, please use `message.template = kiwi.Vue.extend(component object)`');
      }
      return isVue;
    },
    isHoveringOverMessage: function isHoveringOverMessage(message) {
      return message.nick && message.nick.toLowerCase() === this.hover_nick.toLowerCase();
    },
    toggleMessageInfo: function toggleMessageInfo(message) {
      var _this4 = this;
      if (!message) {
        this.message_info_open = null;
      } else if (this.message_info_open === message) {
        // Empty block statement fixed
      } else if (this.canShowInfoForMessage(message)) {
        var sel = window.getSelection();
        if (sel.rangeCount > 0) {
          var range = sel.getRangeAt(0);
          if (range && !range.collapsed) {
            return;
          }
        }
        this.message_info_open = message;
        this.$nextTick(function () {
          return _this4.maybeScrollToId(message.id);
        });
      }
    },
    shouldShowUnreadMarker: function shouldShowUnreadMarker(message) {
      var idx = this.filteredMessages.indexOf(message);
      var previous = this.filteredMessages[idx - 1];
      var current = this.filteredMessages[idx];
      var lastRead = this.buffer.last_read;
      if (!lastRead) {
        return false;
      }
      if (!current) {
        return false;
      }
      if (previous && previous.time < lastRead && current.time > lastRead) {
        return true;
      }
      return false;
    },
    shouldShowDateChangeMarker: function shouldShowDateChangeMarker(idx) {
      var previous = this.filteredMessages[idx - 1];
      var current = this.filteredMessages[idx];
      if (!previous && new Date(current.time).getDay() !== new Date().getDay()) {
        return true;
      } else if (!previous) {
        return false;
      }
      if (new Date(previous.time).getDay() !== new Date(current.time).getDay()) {
        return true;
      }
      return false;
    },
    canShowInfoForMessage: function canShowInfoForMessage(message) {
      var showInfoForTypes = ['privmsg', 'notice', 'action'];
      return showInfoForTypes.indexOf(message.type) > -1;
    },
    bufferSetting: function bufferSetting(key) {
      return this.buffer.setting(key);
    },
    formatTime: function formatTime(time) {
      return (0, _strftime.default)(this.buffer.setting('timestamp_format') || '%T', new Date(time));
    },
    formatTimeFull: function formatTimeFull(time) {
      var format = this.buffer.setting('timestamp_full_format');
      return format ? (0, _strftime.default)(format, new Date(time)) : new Date(time).toLocaleString();
    },
    formatMessage: function formatMessage(message) {
      return message.toHtml(this);
    },
    isMessageHighlight: function isMessageHighlight(message) {
      if (message.type === 'traffic') {
        return false;
      }
      return message.isHighlight;
    },
    userColour: function userColour(user) {
      if (user && this.bufferSetting('colour_nicknames_in_messages')) {
        return user.getColour();
      }
      return '';
    },
    openUserBox: function openUserBox(nick) {
      var user = this.$state.getUser(this.buffer.networkid, nick);
      if (user) {
        this.$state.$emit('userbox.show', user, {
          buffer: this.buffer
        });
      }
    },
    onListClick: function onListClick() {
      this.toggleMessageInfo();
    },
    onMessageDblClick: function onMessageDblClick(event, message) {
      clearTimeout(this.messageClickTmr);
      var dataNick = event.target.getAttribute('data-nick');
      if (!dataNick) {
        return;
      }
      var network = this.buffer.getNetwork();
      var user = network.userByName(dataNick);
      var nick = user && user.nick ? user.nick : dataNick;
      this.$state.$emit('input.insertnick', nick);
    },
    onMessageClick: function onMessageClick(event, message, delay) {
      if (delay && event.target.getAttribute('data-nick')) {
        clearTimeout(this.messageClickTmr);
        this.messageClickTmr = setTimeout(this.onMessageClick, 200, event, message, false);
        return;
      }
      var isLink = event.target.tagName === 'A';
      var channelName = event.target.getAttribute('data-channel-name');
      if (channelName && isLink) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
        this.$state.setActiveBuffer(this.buffer.networkid, channelName);
        return;
      }
      var userNick = event.target.getAttribute('data-nick');
      if (userNick && isLink) {
        this.openUserBox(userNick);
        return;
      }
      var url = event.target.getAttribute('data-url');
      if (url && isLink) {
        if (this.$state.setting('buffers.inline_link_auto_previews')) {
          message.embed.type = 'url';
          message.embed.payload = url;
        } else {
          this.$state.$emit('mediaviewer.show', url);
        }
        return;
      }
      var avatarElement = event.target.closest('.kiwi-avatar');
      if (avatarElement && avatarElement.dataset.nick) {
        this.openUserBox(avatarElement.dataset.nick);
        return;
      }
      if (this.message_info_open && this.message_info_open !== message) {
        this.toggleMessageInfo();
        event.preventDefault();
        return;
      }
      if (this.$state.ui.is_touch && this.$state.setting('buffers.show_message_info')) {
        if (this.canShowInfoForMessage(message) && event.target.nodeName === 'A') {
          event.preventDefault();
        }
        this.toggleMessageInfo(message);
      }
    },
    checkScrollingState: function checkScrollingState() {
      var el = this.$el;
      var scrolledUpByPx = el.scrollHeight - (el.offsetHeight + el.scrollTop);
      if (scrolledUpByPx > BOTTOM_SCROLL_MARGIN) {
        this.auto_scroll = false;
        this.buffer.isMessageTrimming = false;
      } else {
        this.auto_scroll = true;
        this.buffer.isMessageTrimming = true;
      }
      if (this.force_smooth_scroll !== null) {
        this.smooth_scroll = this.force_smooth_scroll;
        this.force_smooth_scroll = null;
      } else {
        this.smooth_scroll = false;
      }
    },
    onListResize: function onListResize() {
      this.maybeScrollToBottom();
    },
    // Method to scroll to bottom and hide button
    scrollToBottom: function scrollToBottom() {
      this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight;
      this.showScrollButton = false;
      this.auto_scroll = true;
    },
    // Method to check if further scrolling is needed
    checkScroll: function checkScroll() {
      var scroller = this.$refs.scroller;
      var isBottom = scroller.scrollHeight - Math.ceil(scroller.scrollTop) <= scroller.clientHeight;
      this.auto_scroll = isBottom;
      this.showScrollButton = !isBottom;
    },
    // Method to handle scrolling event
    handleScroll: function handleScroll() {
      this.checkScroll();
      // Hide the button if the user has scrolled to bottom manually
      if (this.auto_scroll) {
        this.showScrollButton = false;
      }
    },
    maybeScrollToBottom: function maybeScrollToBottom() {
      if (this.auto_scroll) {
        this.scrollToBottom();
      }
    },
    maybeScrollToId: function maybeScrollToId(id) {
      var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'middle';
      var msgEl = this.$el.querySelector(".kiwi-messagelist-message[data-message-id=\"".concat(id, "\"]"));
      if (!msgEl) {
        return;
      }
      var newTop = 0;
      if (position === 'top') {
        newTop = msgEl.offsetTop;
      } else if (position === 'bottom') {
        newTop = Math.floor(msgEl.offsetTop - this.$refs.scroller.offsetHeight + msgEl.offsetHeight);
      } else {
        newTop = Math.floor(msgEl.offsetTop - (this.$refs.scroller.offsetHeight - msgEl.offsetHeight) / 2);
      }
      this.auto_scroll = false;
      this.$refs.scroller.scrollTo({
        top: newTop,
        behavior: 'smooth'
      });
    },
    getSelectedMessages: function getSelectedMessages() {
      var sel = document.getSelection();
      var r = sel.getRangeAt(0);
      var messageEls = (0, _toConsumableArray2.default)(this.$el.querySelectorAll('.kiwi-messagelist-message'));
      var selectedMessageEls = (0, _filter.default)(messageEls).call(messageEls, function (el) {
        return r.intersectsNode(el);
      });
      return selectedMessageEls;
    },
    restrictTextSelection: function restrictTextSelection() {
      document.querySelector('body').classList.add('kiwi-unselectable');
      this.$el.style.userSelect = 'text';
    },
    unrestrictTextSelection: function unrestrictTextSelection() {
      document.querySelector('body').classList.remove('kiwi-unselectable');
      this.$el.style.userSelect = 'auto';
    },
    removeSelections: function removeSelections() {
      var removeNative = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.selectedMessages = Object.create(null);
      var selection = document.getSelection();
      if (removeNative && selection) {
        selection.removeAllRanges();
      }
    },
    addCopyListeners: function addCopyListeners() {
      var _this5 = this;
      var LogFormatter = function LogFormatter(msg) {
        var _context;
        var text = '';
        switch (msg.type) {
          case 'privmsg':
            text = (0, _concat.default)(_context = "<".concat(msg.nick, "> ")).call(_context, msg.message);
            break;
          case 'nick':
          case 'mode':
          case 'action':
          case 'traffic':
            text = "".concat(msg.message);
            break;
          default:
            text = msg.message;
        }
        if (text.length) {
          var _context2;
          return (0, _concat.default)(_context2 = "[".concat(new Date(msg.time).toLocaleTimeString({
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }), "] ")).call(_context2, text);
        }
        return null;
      };
      var copyData = '';
      var selecting = false;
      var selectionChangeOff = null;
      this.listen(document, 'selectstart', function (e) {
        if (!_this5.$el.contains(e.target)) {
          copyData = '';
          _this5.removeSelections();
          return;
        }
        _this5.removeSelections();
        selectionChangeOff = _this5.listen(document, 'selectionchange', onSelectionChange);
      });
      this.listen(document, 'mouseup', function (e) {
        selectionChangeOff && selectionChangeOff();
        _this5.unrestrictTextSelection();
        if (selecting) {
          e.preventDefault();
        }
        selecting = false;
      });
      var onSelectionChange = function onSelectionChange() {
        if (!_this5.$el) {
          return true;
        }
        copyData = '';
        var selection = document.getSelection();
        if (!selection || !selection.anchorNode || !selection.anchorNode.parentNode.closest(".".concat(_this5.$el.className))) {
          _this5.unrestrictTextSelection();
          _this5.removeSelections();
          return true;
        }
        _this5.removeSelections();
        _this5.restrictTextSelection();
        if (selection.rangeCount > 0) {
          var _context3, _context4;
          selecting = true;
          var selectedMesssageEls = _this5.getSelectedMessages();
          var selectedMessages = [];
          selectedMesssageEls.forEach(function (el) {
            var m = _this5.buffer.messagesObj.messageIds[el.dataset.messageId];
            if (m) {
              selectedMessages.push(m);
            }
          });
          if (selectedMessages.length === 1) {
            selectedMessages.length = 0;
          }
          _this5.selectedMessages = Object.create(null);
          selectedMessages.forEach(function (m) {
            _this5.selectedMessages[m.id] = m;
          });
          copyData = (0, _map.default)(_context3 = (0, _filter.default)(_context4 = (0, _sort.default)(selectedMessages).call(selectedMessages, function (a, b) {
            return a.time > b.time ? 1 : -1;
          })).call(_context4, function (m) {
            var _context5;
            return (0, _trim.default)(_context5 = m.message).call(_context5).length;
          })).call(_context3, LogFormatter).join('\r\n');
        } else {
          _this5.unrestrictTextSelection();
        }
        return false;
      };
      this.listen(document, 'copy', function (e) {
        if (!copyData || !copyData.length) {
          return true;
        }
        if (navigator.clipboard) {
          navigator.clipboard.writeText(copyData);
        } else {
          var input = document.createElement('textarea');
          document.body.appendChild(input);
          input.innerHTML = copyData;
          input.select();
          document.execCommand('copy');
          document.body.removeChild(input);
        }
        return true;
      });
    },
    openEmbedInPreview: function openEmbedInPreview(message) {
      var embed = message.embed;
      if (embed.type === 'url') {
        this.$state.$emit('mediaviewer.show', embed.payload);
      } else if (embed.type === 'component') {
        this.$state.$emit('mediaviewer.show', {
          component: embed.payload
        });
      }
      embed.payload = null;
    },
    debounce: function debounce(func, wait) {
      var _this6 = this;
      var timeout;
      return function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          return func.apply(_this6, args);
        }, wait);
      };
    }
  }
};
exports.default = _default;